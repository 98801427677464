import React, { useEffect, useContext, useState, useRef } from "react";
import { useAuth } from "../../../context/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";

const Install = () => {
  const { currentUser } = useAuth();
  const [sendEmailVerificationIsLoading, setSendEmailVerificationIsLoading] =
    useState(false);
  const [copyButtonIsLoading, setCopyButtonIsLoading] = useState(false);
  const textAreaRef = useRef(null);
  const { dashboardData } = useDashboard();
  const { addNotification } = useNotification();

  const iframeSrc = `<script src="https://widget.fillyform.com/script.js?cid=${dashboardData.user.companyId ? dashboardData.user.companyId : ""}"></script>`;

  const resendVerificationEmail = () => {
    setSendEmailVerificationIsLoading(true);
    sendEmailVerification(currentUser)
      .then(() => {
        setSendEmailVerificationIsLoading(false);
        addNotification({
          title: "Success!",
          message: "Verification email sent successfully",
          icon: "check_circle",
          type: "success",
        });
      })
      .catch(() => {
        setSendEmailVerificationIsLoading(false);
        addNotification({
          title: "Error!",
          message: "Something went wrong, please try again.",
          icon: "warning",
          type: "error",
        });
      });
  };

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopyButtonIsLoading(true);
    addNotification({
      title: "Success!",
      message: "Script copied to clipboard",
      icon: "check_circle",
      type: "success",
    });
    setTimeout(() => {
      setCopyButtonIsLoading(false);
    }, 1000);
  };

  if (currentUser && !currentUser.emailVerified) {
    return (
      <div className="mt-80 mb-80">
        <div className="large-container">
          <div className="installation-instructions">
            <h1>Email Verification Required</h1>
            <p>To publish your widget, please verify your email address.</p>
            <button
              className={`primary-button blue-button medium-button mt-40 ${sendEmailVerificationIsLoading ? "loading disabled" : ""}`}
              onClick={resendVerificationEmail}
              disabled={sendEmailVerificationIsLoading}
            >
              {sendEmailVerificationIsLoading
                ? "Sending..."
                : "Resend Verification Email"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="installation-steps">
      <h2>Follow these steps to install your widget:</h2>
      <ol>
        <li>Add your field configurations and questions.</li>
        <li>Publish the changes.</li>
        <li>
          Copy the following code:
          <code>
            {iframeSrc}
            {document.queryCommandSupported("copy") && (
              <span
                className={`icon copy-button secondary-button ${copyButtonIsLoading ? "disabled" : ""}`}
                onClick={copyToClipboard}
              >
                {copyButtonIsLoading ? "done" : "content_copy"}
              </span>
            )}
          </code>
        </li>
        <li>Paste the code at the end of the body tag on your website.</li>
        <li>You're all set!</li>
      </ol>
      <form>
        <textarea ref={textAreaRef} value={iframeSrc} readOnly />
      </form>
    </div>
  );
};

export default Install;
