import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const DashboardTabs = (props) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  const updateLocation = (currentLocation) => {
    setCurrentLocation(currentLocation);
  };

  return (
    <div className={props.cssClasses}>
      <div className="dashboard-tabs">
        {props.links.map((link) => (
          <Link
            to={link.url}
            key={link.url}
            className={`${currentLocation === link.url ? "active" : ""}`}
            onClick={() => updateLocation(link.url)}
          >
            {link.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DashboardTabs;
