import React, { useState, useEffect } from "react";
import { useDashboard } from "../context/DashboardContext";
import { useAuth } from "../context/AuthContext";
import { useNotification } from "../context/NotificationContext";
import Sidebar from "../components/Sidebar";
import { Routes, Route } from "react-router-dom";
import AccountSettings from "./dashboard/profile/AccountSettings";
import WidgetSettings from "./dashboard/widget/WidgetSettings";
import Store from "./dashboard/store/Store";
import Subscriptions from "./dashboard/subscriptions/Subscriptions";
import Insights from "./dashboard/insights/Insights";
import Install from "./dashboard/install/Install";
import logo from "../assets/images/fillyform-logo.svg";

import { sendEmailVerification } from "firebase/auth";

const Dashboard = () => {
  const { dashboardData } = useDashboard();
  const { currentUser } = useAuth();
  const [isSending, setIsSending] = useState(false);

  const { addNotification } = useNotification();

  useEffect(() => {}, [dashboardData]);

  const handleResendEmail = async () => {
    setIsSending(true);
    try {
      await sendEmailVerification(currentUser);
      addNotification({
        icon: "check_circle",
        title: "Success",
        message: "Validation email sent. Please check your inbox.",
        type: "success",
      });
    } catch (error) {
      addNotification({
        icon: "error",
        title: "Failed to resend validation email:",
        message: `${error}`,
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className="page-content">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="mobile-sidebar">
          <img src={logo} className="logo" alt="Filly Form" />
          <span className="icon" onClick={() => setSidebarOpen(!sidebarOpen)}>
            menu
          </span>
        </div>
        <div className="view-wrapper">
          {!currentUser?.emailVerified && (
            <div className="banner">
              <div className="text">
                <div className="icon">error</div>
                <p>
                  Your account is not validated. Please check your email to
                  validate your account.
                </p>
              </div>
              <div>
                <button
                  onClick={handleResendEmail}
                  className={`primary-button ${isSending ? "loading" : ""}`}
                  disabled={isSending}
                >
                  Resend Validation Email
                </button>
              </div>
            </div>
          )}
          <div className="view">
            <Routes>
              <Route path="account-settings/*" element={<AccountSettings />} />
              <Route path="fields/*" element={<WidgetSettings />} />
              <Route path="store/*" element={<Store />} />
              <Route path="subscriptions/*" element={<Subscriptions />} />
              <Route path="insights/*" element={<Insights />} />
              <Route path="install/*" element={<Install />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
