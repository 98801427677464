import React, { useState } from "react";
import { auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import logo from "../../assets/images/fillyform-logo.svg";
import googleLogo from "../../assets/images/google-logo.png";
import { Link } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);

  const validateField = (name, value) => {
    const strongEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    let error = "";
    switch (name) {
      case "email":
        if (!value) {
          error = "Email is required.";
        } else if (!strongEmailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;
      case "password":
        if (!value) {
          error = "Password is required.";
        } else if (!passwordRegex.test(value)) {
          error =
            "Password must be 8+ characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 digit.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    const emailError = validateField("email", formData.email);
    const passwordError = validateField("password", formData.password);

    if (emailError) {
      formIsValid = false;
      newErrors["email"] = emailError;
    }

    if (passwordError) {
      formIsValid = false;
      newErrors["password"] = passwordError;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Optionally validate fields on change
    const fieldError = validateField(name, value);
    setErrors({ ...errors, [name]: fieldError });
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/wrong-password":
        return "Incorrect password. Please try again.";
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/user-disabled":
        return "This user has been disabled.";
      case "auth/too-many-requests":
        return "Too many requests. Please try again later.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoginLoading(true);
    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      setIsLoginLoading(false);
      // Redirect to dashboard or another page
    } catch (error) {
      setIsLoginLoading(false);
      setErrors({ ...errors, firebase: getErrorMessage(error.code) });
    }
  };

  const handleGoogleSignIn = async () => {
    setIsGoogleSignInLoading(true);
    try {
      await signInWithPopup(auth, new GoogleAuthProvider());
      setIsGoogleSignInLoading(false);
      // Redirect to dashboard or another page
    } catch (error) {
      setIsGoogleSignInLoading(false);
      setErrors({ ...errors, firebase: getErrorMessage(error.code) });
    }
  };

  return (
    <div className="auth-container">
      <img src={logo} alt="FillyForm" className="logo" />
      <form onSubmit={handleLogin}>
        <div className="form-header">
          <h1>Sign in</h1>
          <p>
            New to FillyForm?{" "}
            <Link className="link" to={`/register`}>
              Sign up here
            </Link>
          </p>
        </div>

        <button
          type="button"
          onClick={handleGoogleSignIn}
          className={`sm-button ${isGoogleSignInLoading ? "loading" : ""}`}
          disabled={isGoogleSignInLoading}
        >
          <img src={googleLogo} alt="Sign in with Google" /> Sign in with Google
        </button>
        <div className="auth-spacer">or</div>

        <fieldset>
          <div className="form-field">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address"
              className={errors.email ? "has-error" : ""}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
        </fieldset>

        <fieldset>
          <div className="form-field">
            <label>
              Password{" "}
              <Link className="link" to={`/reset-password`}>
                Forgot password?
              </Link>
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              className={errors.password ? "has-error" : ""}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="icon show-password"
            >
              {showPassword ? "visibility" : "visibility_off"}
            </span>
            {errors.password && <div className="error">{errors.password}</div>}
          </div>
        </fieldset>

        <button
          type="submit"
          className={`primary-button ${isLoginLoading ? "loading" : ""}`}
          disabled={isLoginLoading}
        >
          Sign in
        </button>
        {errors.firebase && <div className="error">{errors.firebase}</div>}
      </form>
      <p className="copyrights">
        © {new Date().getFullYear()} Answers Automate
      </p>
    </div>
  );
};

export default Login;
