// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Adjust the path as necessary
import { NotificationProvider } from "./context/NotificationContext";
import { DashboardProvider } from "./context/DashboardContext";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/Dashboard";
import ResetPassword from "./pages/auth/ResetPassword";
import "./assets/style/style.scss";

const App = () => {
  return (
    <Router>
      <NotificationProvider>
        <AuthProvider>
          <DashboardProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
            </Routes>
          </DashboardProvider>
        </AuthProvider>
      </NotificationProvider>
    </Router>
  );
};

export default App;
