import React, { useState, useEffect } from "react";
import axios from "axios";

const PreviewModal = ({ isOpen, onClose, field, companyId }) => {
  const [previewStep, setPreviewStep] = useState(1);
  const [responses, setResponses] = useState({});
  const [answers, setAnswers] = useState([]);
  const [generatedText, setGeneratedText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
      if (!shouldDisplayQuestion(field.questions[previewStep - 1])) {
        setPreviewStep((prevStep) => getNextStep(prevStep));
      }
    } else {
      document.body.classList.remove("overflow-hidden");
      resetState();
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const resetState = () => {
    setPreviewStep(1);
    setResponses({});
    setAnswers([]);
    setGeneratedText(null);
    setIsLoading(false);
  };

  const handleResponseChange = (questionIndex, value) => {
    const updatedResponses = {
      ...responses,
      [questionIndex]: value,
    };
    setResponses(updatedResponses);
    updateAnswers(questionIndex, value);
  };

  const updateAnswers = (questionIndex, value) => {
    const updatedAnswers = [...answers];
    const question = field.questions[questionIndex];
    updatedAnswers[questionIndex] = {
      index: question.quote,
      answer: Array.isArray(value) ? value.join(", ") : value,
    };
    setAnswers(updatedAnswers);
  };

  const isNextEnabled = () => {
    const response = responses[previewStep - 1];
    if (Array.isArray(response)) {
      return response.length > 0;
    }
    return response && response.trim() !== "";
  };

  const shouldDisplayQuestion = (question) => {
    if (!question.hasConditional) {
      return true;
    }
    const condition = field.questions[question.conditions.slideNo - 1];
    if (!condition) {
      return false;
    }
    const conditionValue = responses[question.conditions.slideNo - 1];
    switch (question.conditions.condition) {
      case "equals":
        return Array.isArray(conditionValue)
          ? conditionValue.includes(question.conditions.value)
          : conditionValue === question.conditions.value;
      case "notequals":
        return Array.isArray(conditionValue)
          ? !conditionValue.includes(question.conditions.value)
          : conditionValue !== question.conditions.value;
      case "contains":
        return Array.isArray(conditionValue)
          ? conditionValue.includes(question.conditions.value)
          : conditionValue.includes(question.conditions.value);
      case "notcontains":
        return Array.isArray(conditionValue)
          ? !conditionValue.includes(question.conditions.value)
          : !conditionValue.includes(question.conditions.value);
      default:
        return false;
    }
  };

  const getNextStep = (currentStep) => {
    let nextStep = currentStep + 1;
    while (
      nextStep <= field.questions.length &&
      !shouldDisplayQuestion(field.questions[nextStep - 1])
    ) {
      nextStep++;
    }
    return nextStep;
  };

  const renderInputField = (question, index) => {
    switch (question.type) {
      case "text":
        return (
          <div className="form-field">
            <label>{question.quote}</label>
            <input
              type="text"
              placeholder={question.placeholder}
              value={responses[index] || ""}
              onChange={(e) => handleResponseChange(index, e.target.value)}
            />
          </div>
        );
      case "singleSelect":
        return (
          <div className="form-field">
            <label>{question.quote}</label>
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex}>
                <input
                  type="radio"
                  id={`option${optionIndex}`}
                  name={`singleSelect${index}`}
                  value={option}
                  checked={responses[index] === option}
                  onChange={(e) => handleResponseChange(index, e.target.value)}
                />
                <label htmlFor={`option${optionIndex}`}>{option}</label>
              </div>
            ))}
          </div>
        );
      case "multiSelect":
        return (
          <div className="form-field">
            <label>{question.quote}</label>
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex}>
                <input
                  type="checkbox"
                  id={`option${optionIndex}`}
                  name={`multiSelect${index}`}
                  value={option}
                  checked={
                    Array.isArray(responses[index]) &&
                    responses[index].includes(option)
                  }
                  onChange={(e) => {
                    const newValue = Array.isArray(responses[index])
                      ? responses[index].includes(option)
                        ? responses[index].filter((val) => val !== option)
                        : [...responses[index], option]
                      : [option];
                    handleResponseChange(index, newValue);
                  }}
                />
                <label htmlFor={`option${optionIndex}`}>{option}</label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const renderCurrentStep = () => {
    if (generatedText) {
      return (
        <div className="preview-step">
          <p className="generated-text">{generatedText}</p>
          <div className="modal-actions">
            <button className="secondary-button" onClick={resetState}>
              Reset
            </button>
          </div>
        </div>
      );
    }

    const question = field.questions[previewStep - 1];
    if (!shouldDisplayQuestion(question)) {
      return null;
    }
    return (
      <div className="preview-step form">
        {renderInputField(question, previewStep - 1)}
        <div className="modal-actions">
          {previewStep < field.questions.length ? (
            <button
              onClick={() => setPreviewStep(getNextStep(previewStep))}
              className="secondary-button"
              disabled={!isNextEnabled()}
            >
              Next
            </button>
          ) : (
            <button
              onClick={handleGenerateClick}
              className={`primary-button ${isLoading ? "loading" : ""}`}
              disabled={isLoading}
            >
              Generate
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleGenerateClick = async () => {
    setIsLoading(true);

    const displayedAnswers = answers.filter(
      (answer, index) =>
        shouldDisplayQuestion(field.questions[index]) && answer.answer,
    );

    const prompt = `You are a senior copywriter. Write me a compelling and engaging text in ${field.language}, staying as close as possible to the ${field.charLimit} character limit without exceeding it. Adapt your tone and style to match the following description: ${field.fieldDescription}. Use the following questions and answers to inform the text: ${displayedAnswers
      .map(
        (answer) =>
          `${answer.index}: ${
            Array.isArray(responses[answers.indexOf(answer)])
              ? responses[answers.indexOf(answer)].join(", ")
              : responses[answers.indexOf(answer)]
          }`,
      )
      .join(
        ", ",
      )}. Ensure the text captures attention, maintains a natural flow, and sounds engaging and persuasive. Include a repeated call to action to drive the desired listener response. Expand content to utilize as many characters as possible without being redundant.`;

    const body = {
      prompt,
    };

    try {
      const response = await axios.post(
        `https://api.fillyform.com/generate?isPreview=true&companyId=${companyId}&fieldId=${field.id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      setGeneratedText(response.data.message);
    } catch (error) {
      console.error("Error generating text:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close icon" onClick={onClose}>
          close
        </span>
        <span className="modal-icon icon">preview</span>

        <h2>
          {field?.name ? (
            <>
              Preview for: <i className="text-primary-accent">{field?.name}</i>
            </>
          ) : (
            "Preview"
          )}
        </h2>
        <div className="modal-preview">{renderCurrentStep()}</div>
      </div>
    </div>
  );
};

export default PreviewModal;
