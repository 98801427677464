import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  addDoc,
  getDoc,
} from "firebase/firestore";
import DOMPurify from "dompurify";
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";
import PreviewModal from "./PreviewModal";

const FieldForm = () => {
  const { fieldId } = useParams();
  const { dashboardData } = useDashboard();
  const [field, setField] = useState({
    name: "",
    cssSelector: "",
    charLimit: "",
    page: "",
    language: "English",
    fieldDescription: "",
    storeData: false,
    published: false,
    questions: [],
    active: true,
  });

  const europeanLanguages = [
    { name: "English", code: "en" },
    { name: "French", code: "fr" },
    { name: "German", code: "de" },
    { name: "Spanish", code: "es" },
    { name: "Italian", code: "it" },
    { name: "Portuguese", code: "pt" },
    { name: "Russian", code: "ru" },
    { name: "Dutch", code: "nl" },
    { name: "Greek", code: "el" },
    { name: "Swedish", code: "sv" },
    { name: "Danish", code: "da" },
    { name: "Norwegian", code: "no" },
    { name: "Finnish", code: "fi" },
    { name: "Polish", code: "pl" },
    { name: "Czech", code: "cs" },
    { name: "Hungarian", code: "hu" },
    { name: "Romanian", code: "ro" },
    { name: "Bulgarian", code: "bg" },
    { name: "Serbian", code: "sr" },
    { name: "Croatian", code: "hr" },
    { name: "Slovak", code: "sk" },
    { name: "Slovenian", code: "sl" },
    { name: "Turkish", code: "tr" },
    { name: "Ukrainian", code: "uk" },
    { name: "Irish", code: "ga" },
    { name: "Icelandic", code: "is" },
    { name: "Latvian", code: "lv" },
    { name: "Lithuanian", code: "lt" },
    { name: "Estonian", code: "et" },
    { name: "Basque", code: "eu" },
    { name: "Catalan", code: "ca" },
    { name: "Bosnian", code: "bs" },
    { name: "Macedonian", code: "mk" },
    { name: "Welsh", code: "cy" },
  ];

  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFieldLoaded, setIsFieldLoaded] = useState(false);
  const [expandedSteps, setExpandedSteps] = useState([]);
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstLoad = useRef(true);

  const planLimitations = dashboardData?.activePlan?.limitations || {};
  const maxSteps = planLimitations?.steps || 1; // Maximum question slides allowed
  const canAddConditionals = planLimitations?.conditionals || false;
  const maxOptions = 20;

  useEffect(() => {
    const companyId = dashboardData.user.companyId;
    const fieldsRef = collection(db, "companies", companyId, "fields");

    const unsubscribe = onSnapshot(fieldsRef, (snapshot) => {
      const updatedFields = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dashboardData.company.fields = updatedFields;

      if (fieldId) {
        const existingField = updatedFields.find((f) => f.id === fieldId);
        if (existingField) {
          setField(existingField);
          setIsFieldLoaded(true);
        }
      } else {
        setIsFieldLoaded(true);
      }
    });

    return () => unsubscribe();
  }, [dashboardData.user.companyId, fieldId, dashboardData.company]);

  useEffect(() => {
    if (isFieldLoaded && isFirstLoad.current) {
      // Initialize expanded states for question slides
      const newExpandedSteps = field.questions.map(
        (_, i) => i === field.questions.length - 1,
      );
      setExpandedSteps(newExpandedSteps);
      isFirstLoad.current = false;
    }
  }, [isFieldLoaded, field.questions.length]);

  useEffect(() => {
    const checkRoute = () => {
      if (isFieldLoaded) {
        if (location.pathname.endsWith("/preview")) {
          if (field.questions.length === 0) {
            navigate(location.pathname.replace("/preview", ""), {
              replace: true,
            });
          } else {
            setIsModalOpen(true);
          }
        } else {
          setIsModalOpen(false);
        }
      }
    };

    checkRoute();
  }, [location.pathname, field.questions.length, navigate, isFieldLoaded]);

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    const sanitizedValue = DOMPurify.sanitize(
      type === "checkbox" ? checked : value,
    );
    setField({
      ...field,
      [name]: sanitizedValue,
    });
    if (errors[name]) {
      validateFields();
    }
  };

  const handleQuestionChange = (index, name, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newQuestions = [...field.questions];
    newQuestions[index][name] = sanitizedValue;
    setField({ ...field, questions: newQuestions });
    if (
      errors[`question${index}`] ||
      errors[`question${index}options`] ||
      errors[`question${index}condition`] ||
      errors[`question${index}value`]
    ) {
      validateFields();
    }
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newQuestions = [...field.questions];
    newQuestions[questionIndex].options[optionIndex] = sanitizedValue;
    setField({ ...field, questions: newQuestions });
  };

  const handleConditionChange = (questionIndex, name, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newQuestions = [...field.questions];
    newQuestions[questionIndex].conditions = {
      ...newQuestions[questionIndex].conditions,
      [name]: sanitizedValue,
    };
    setField({ ...field, questions: newQuestions });
    if (
      errors[`question${questionIndex}condition`] ||
      errors[`question${questionIndex}value`]
    ) {
      validateFields();
    }
  };

  const addQuestion = () => {
    setField((prevField) => {
      const newQuestions = [
        ...prevField.questions,
        {
          quote: "",
          type: "text",
          placeholder: "",
          options: [],
          hasConditional: false,
          conditions: { slideNo: "", condition: "equals", value: "" },
        },
      ];
      return { ...prevField, questions: newQuestions };
    });

    setExpandedSteps((prevExpandedSteps) => [
      ...prevExpandedSteps.map(() => false),
      true,
    ]);
  };

  const addOption = (index) => {
    const newQuestions = [...field.questions];
    if (!Array.isArray(newQuestions[index].options)) {
      newQuestions[index].options = [];
    }
    if (newQuestions[index].options.length < maxOptions) {
      newQuestions[index].options.push("");
      setField({ ...field, questions: newQuestions });
    } else {
      addNotification({
        title: "Error",
        message: `You can only add up to ${maxOptions} options.`,
        icon: "error",
        type: "error",
      });
    }
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...field.questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setField({ ...field, questions: newQuestions });
    validateFields();
  };

  const confirmDeleteQuestion = (index) => {
    removeQuestion(index);
    setDeleteConfirmationIndex(null);
  };

  const cancelDeleteQuestion = () => {
    setDeleteConfirmationIndex(null);
  };

  const removeQuestion = (index) => {
    const newQuestions = [...field.questions];
    newQuestions.splice(index, 1);
    setField({ ...field, questions: newQuestions });

    setExpandedSteps((prevExpandedSteps) => {
      const newExpandedSteps = [...prevExpandedSteps];
      newExpandedSteps.splice(index, 1);
      return newExpandedSteps;
    });
  };

  const validateFields = () => {
    let valid = true;
    let newErrors = {};

    if (!field.name) {
      newErrors.name = "Field name is required.";
      valid = false;
    } else if (field.name.length > 100) {
      newErrors.name = "Field name cannot exceed 100 characters.";
      valid = false;
    }

    if (!field.cssSelector) {
      newErrors.cssSelector = "CSS selector is required.";
      valid = false;
    }
    // No strict validation on CSS selector.

    if (!field.charLimit) {
      newErrors.charLimit = "Maximum length is required.";
      valid = false;
    } else if (!/^\d+$/.test(field.charLimit)) {
      newErrors.charLimit = "Maximum length must be a number.";
      valid = false;
    }

    if (!field.language) {
      newErrors.language = "Field language is required.";
      valid = false;
    }

    field.questions.forEach((question, index) => {
      if (!question.quote) {
        newErrors[`question${index}`] = "Question name is required.";
        valid = false;
      } else if (question.quote.length > 200) {
        newErrors[`question${index}`] =
          "Question name cannot exceed 200 characters.";
        valid = false;
      }
      if (
        (question.type === "singleSelect" || question.type === "multiSelect") &&
        question.options.length === 0
      ) {
        newErrors[`question${index}options`] =
          "Options are required for select type questions.";
        valid = false;
      }
      if (question.hasConditional) {
        if (!question.conditions.slideNo || question.conditions.slideNo <= 0) {
          newErrors[`question${index}condition`] =
            "Slide no must be a positive number.";
          valid = false;
        }
        if (!question.conditions.value) {
          newErrors[`question${index}value`] = "Condition value is required.";
          valid = false;
        }
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const isLastSlideComplete = () => {
    if (field.questions.length === 0) return true;
    const lastQuestion = field.questions[field.questions.length - 1];

    if (!lastQuestion.quote || !lastQuestion.type) return false;

    if (
      (lastQuestion.type === "singleSelect" ||
        lastQuestion.type === "multiSelect") &&
      (!lastQuestion.options || lastQuestion.options.length === 0)
    ) {
      return false;
    }

    if (lastQuestion.hasConditional) {
      if (
        !lastQuestion.conditions.slideNo ||
        lastQuestion.conditions.slideNo <= 0 ||
        !lastQuestion.conditions.condition ||
        !lastQuestion.conditions.value
      ) {
        return false;
      }
    }

    return true;
  };

  const isFormValid = () => {
    // Basic checks
    if (
      !field.name ||
      field.name.length > 100 ||
      !field.cssSelector ||
      !field.charLimit ||
      !/^\d+$/.test(field.charLimit) ||
      !field.language
    ) {
      return false;
    }

    // Check last slide completeness
    if (!isLastSlideComplete()) {
      return false;
    }

    return true;
  };

  const saveField = async () => {
    if (!validateFields()) {
      return;
    }

    if (!isLastSlideComplete()) {
      addNotification({
        title: "Error",
        message: "Please complete the last slide before saving.",
        icon: "error",
        type: "error",
      });
      return;
    }

    setIsSaving(true);

    try {
      const companyId = dashboardData.user.companyId;
      const companyRef = doc(db, "companies", companyId);
      const fieldsRef = collection(companyRef, "fields");

      let newFieldId = null;
      if (fieldId) {
        const fieldDocRef = doc(fieldsRef, fieldId);
        const fieldSnap = await getDoc(fieldDocRef);

        if (fieldSnap.exists()) {
          await setDoc(
            fieldDocRef,
            { ...fieldSnap.data(), ...field },
            { merge: true },
          );
        } else {
          await setDoc(fieldDocRef, { ...field }, { merge: true });
        }
      } else {
        const newFieldRef = await addDoc(fieldsRef, field);
        newFieldId = newFieldRef.id;
      }

      addNotification({
        title: "Success!",
        message: "Field saved successfully.",
        icon: "check_circle",
        type: "success",
      });

      if (newFieldId) {
        navigate(`/dashboard/fields/edit/${newFieldId}`);
      }
    } catch (error) {
      console.error("Error saving field:", error);
      addNotification({
        title: "Error",
        message: `Error saving field: ${error.message}`,
        icon: "error",
        type: "error",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const openPreviewModal = () => {
    navigate(`${location.pathname}/preview`);
  };

  const closePreviewModal = () => {
    navigate(location.pathname.replace("/preview", ""));
  };

  const isPreviewButtonEnabled = () => {
    return field.questions.some((question) => {
      return (
        question.quote &&
        question.type &&
        (question.type === "text" ||
          (question.type === "singleSelect" && question.options.length > 0) ||
          (question.type === "multiSelect" && question.options.length > 0))
      );
    });
  };

  const toggleStep = (stepIndex) => {
    setExpandedSteps((prevExpandedSteps) => {
      const newExpandedSteps = [...prevExpandedSteps];
      newExpandedSteps[stepIndex] = !newExpandedSteps[stepIndex];
      return newExpandedSteps;
    });
  };

  if (!isFieldLoaded) {
    return (
      <div className="center-layout fields-editor">
        <div className="loading-screen">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="center-layout fields-editor">
      <div className="description">
        <div className="title-section">
          <h2>{fieldId ? "Edit field" : "Add your new field"}</h2>
          <p>
            Configure your new form field, where FillyForm will prompt users to
            help fill in the required text
          </p>
        </div>
      </div>
      <div className="form">
        {/* Field settings (always expanded) */}
        <div className="step step-settings">
          <div className="step-header">
            <h3>Field settings</h3>
          </div>
          <div className="step-content">
            <div className={`form-field ${errors.name ? "has-error" : ""}`}>
              <label>
                Field name - this will be used internally for identification
              </label>
              <input
                type="text"
                name="name"
                value={field?.name || ""}
                onChange={handleFieldChange}
                placeholder="Job application - personal description"
                maxLength="100"
                className={errors.name ? "has-error" : ""}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
            <div
              className={`form-field ${errors.cssSelector ? "has-error" : ""}`}
            >
              <label>
                Field CSS selector{" "}
                <a
                  href="google.com"
                  target="_blank"
                  className="link"
                  rel="noreferrer"
                >
                  See guide
                </a>
              </label>
              <input
                type="text"
                name="cssSelector"
                value={field?.cssSelector || ""}
                onChange={handleFieldChange}
                placeholder="input[type=email].hs-input"
                className={errors.cssSelector ? "has-error" : ""}
              />
              {errors.cssSelector && (
                <div className="error">{errors.cssSelector}</div>
              )}
            </div>
            <div
              className={`form-field ${errors.charLimit ? "has-error" : ""}`}
            >
              <label>Maximum length - maximum number of chars accepted</label>
              <input
                type="number"
                name="charLimit"
                value={field?.charLimit || ""}
                onChange={handleFieldChange}
                placeholder="250"
                className={errors.charLimit ? "has-error" : ""}
              />
              {errors.charLimit && (
                <div className="error">{errors.charLimit}</div>
              )}
            </div>
            <div
              className={`form-field ${
                errors.fieldDescription ? "has-error" : ""
              }`}
            >
              <label>Field description </label>
              <input
                type="text"
                name="fieldDescription"
                value={field?.fieldDescription || ""}
                onChange={handleFieldChange}
                placeholder="What's this field about"
                className={errors.fieldDescription ? "has-error" : ""}
              />
              {errors.fieldDescription && (
                <div className="error">{errors.fieldDescription}</div>
              )}
            </div>
            <div className={`form-field ${errors.language ? "has-error" : ""}`}>
              <label>Field Language</label>
              <select
                name="language"
                value={field.language}
                onChange={handleFieldChange}
                className={errors.language ? "has-error" : ""}
              >
                {europeanLanguages.map((language) => (
                  <option key={language.code} value={language.code}>
                    {language.name}
                  </option>
                ))}
              </select>
              {errors.language && (
                <div className="error">{errors.language}</div>
              )}
            </div>
            <div className="form-field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="storeData"
                  checked={!!field.storeData}
                  onChange={handleFieldChange}
                  className="visually-hidden custom-checkbox"
                />
                <span className="checkbox-indicator"></span> Store generated
                data
              </label>
            </div>
            <div className="form-field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="published"
                  checked={!!field.published}
                  onChange={handleFieldChange}
                  className="visually-hidden custom-checkbox"
                />
                <span className="checkbox-indicator"></span> Published
              </label>
            </div>
          </div>
        </div>

        {field.questions.map((question, index) => (
          <div
            key={index}
            className={`step ${expandedSteps[index] ? "opened" : ""}`}
          >
            <div className="step-header">
              <h3>
                <div className="header-text">
                  Slide {index + 1}
                  <span>{question.quote}</span>
                </div>

                <div className="step-actions">
                  <button
                    className={`expand-collapse-button icon-button icon ${
                      expandedSteps[index] ? "opened" : ""
                    }`}
                    onClick={() => toggleStep(index)}
                  >
                    add
                  </button>
                  <button
                    className="delete-button icon icon-button"
                    onClick={() => setDeleteConfirmationIndex(index)}
                  >
                    delete
                  </button>
                </div>
              </h3>
            </div>
            {deleteConfirmationIndex === index && (
              <div className="delete-confirmation">
                <p>Are you sure you want to delete this slide?</p>
                <div className="actions">
                  <button
                    onClick={cancelDeleteQuestion}
                    className="secondary-button"
                  >
                    No
                  </button>
                  <button
                    onClick={() => confirmDeleteQuestion(index)}
                    className="red-button"
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
            {expandedSteps[index] && (
              <div className="step-content">
                <div
                  className={`form-field ${
                    errors[`question${index}`] ? "has-error" : ""
                  }`}
                >
                  <label>Question name (type your question)</label>
                  <input
                    type="text"
                    value={question.quote}
                    onChange={(e) =>
                      handleQuestionChange(index, "quote", e.target.value)
                    }
                    placeholder="What’s the name of your product?"
                    maxLength="200"
                    className={errors[`question${index}`] ? "has-error" : ""}
                  />
                  {errors[`question${index}`] && (
                    <div className="error">{errors[`question${index}`]}</div>
                  )}
                </div>
                <div className="form-field">
                  <label>Answer type</label>
                  <select
                    value={question.type}
                    onChange={(e) =>
                      handleQuestionChange(index, "type", e.target.value)
                    }
                  >
                    <option value="text">Text</option>
                    <option value="singleSelect">Single select</option>
                    <option value="multiSelect">Multi select</option>
                  </select>
                </div>
                {/* New optional placeholder input */}
                <div className="form-field">
                  <label>Placeholder (optional)</label>
                  <input
                    type="text"
                    value={question.placeholder || ""}
                    onChange={(e) =>
                      handleQuestionChange(index, "placeholder", e.target.value)
                    }
                    placeholder="Placeholder"
                  />
                </div>

                {(question.type === "singleSelect" ||
                  question.type === "multiSelect") && (
                  <div
                    className={`form-field options-wrapper ${
                      errors[`question${index}options`] ? "has-error" : ""
                    }`}
                  >
                    <label>Add options</label>
                    {Array.isArray(question.options) &&
                      question.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="option-field field-actions-wrapper"
                        >
                          <input
                            type="text"
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                e.target.value,
                              )
                            }
                            placeholder={`Option ${optionIndex + 1}`}
                            maxLength="100"
                          />
                          <span
                            className="icon accent-link"
                            onClick={() => removeOption(index, optionIndex)}
                          >
                            delete
                          </span>
                        </div>
                      ))}
                    {errors[`question${index}options`] && (
                      <div className="error">
                        {errors[`question${index}options`]}
                      </div>
                    )}
                    {question.options.length < maxOptions && (
                      <span
                        className="accent-link"
                        onClick={() => addOption(index)}
                      >
                        Add new +
                      </span>
                    )}
                  </div>
                )}
                {canAddConditionals && index > 0 && (
                  <>
                    <div className="form-field-checkbox">
                      <label>
                        <input
                          type="checkbox"
                          checked={!!question.hasConditional || false}
                          onChange={(e) =>
                            handleQuestionChange(
                              index,
                              "hasConditional",
                              e.target.checked,
                            )
                          }
                          className="visually-hidden custom-checkbox"
                        />
                        <span className="checkbox-indicator"></span> Add display
                        condition
                      </label>
                    </div>
                    {question.hasConditional && (
                      <div className="form-field conditions">
                        <div className="slide-no">
                          <label>Slide no</label>
                          <select
                            value={question.conditions?.slideNo || ""}
                            onChange={(e) =>
                              handleConditionChange(
                                index,
                                "slideNo",
                                e.target.value,
                              )
                            }
                          >
                            {Array.from({ length: index }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="condition-value">
                          <label>Condition</label>
                          <select
                            value={question.conditions?.condition || "equals"}
                            onChange={(e) =>
                              handleConditionChange(
                                index,
                                "condition",
                                e.target.value,
                              )
                            }
                          >
                            <option value="equals">Equals</option>
                            <option value="notequals">Not equals</option>
                            <option value="contains">Contains</option>
                            <option value="notcontains">Not contains</option>
                          </select>
                        </div>
                        <div className="value">
                          <label>Value</label>
                          <input
                            type="text"
                            value={question.conditions?.value || ""}
                            onChange={(e) =>
                              handleConditionChange(
                                index,
                                "value",
                                e.target.value,
                              )
                            }
                            placeholder="Value"
                            maxLength="100"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        ))}
        {field.questions.length < maxSteps && (
          <button onClick={addQuestion} className="add-new-card-button">
            Add new slide
          </button>
        )}
        <div className="actions">
          {isPreviewButtonEnabled() && (
            <button onClick={openPreviewModal} className="secondary-button">
              Preview
            </button>
          )}
          <button
            onClick={saveField}
            className={`primary-button ${isSaving ? "loading" : ""}`}
            disabled={isSaving || !isFormValid()}
          >
            Save
          </button>
        </div>
      </div>
      <PreviewModal
        isOpen={isModalOpen}
        onClose={closePreviewModal}
        field={field}
        companyId={dashboardData.user.companyId}
      />
    </div>
  );
};

export default FieldForm;
