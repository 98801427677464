import React, { useState } from "react";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import logo from "../../assets/images/fillyform-logo.svg";
import googleLogo from "../../assets/images/google-logo.png";
import { Link } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAgreed: false,
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!formData.name) {
      formIsValid = false;
      newErrors["name"] = "Name is required.";
    }

    if (!formData.email) {
      formIsValid = false;
      newErrors["email"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      newErrors["email"] = "Email is invalid.";
    }

    if (!formData.password) {
      formIsValid = false;
      newErrors["password"] = "Password is required.";
    } else if (formData.password.length < 6) {
      formIsValid = false;
      newErrors["password"] =
        "Password must be 8+ characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 digit.";
    }

    if (!formData.confirmPassword) {
      formIsValid = false;
      newErrors["confirmPassword"] = "Confirming password is required.";
    } else if (formData.password !== formData.confirmPassword) {
      formIsValid = false;
      newErrors["confirmPassword"] = "Passwords do not match.";
    }

    if (!formData.termsAgreed) {
      formIsValid = false;
      newErrors["termsAgreed"] = "You must agree to the terms and policy.";
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const validateField = (name, value) => {
    const strongEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    switch (name) {
      case "name":
        if (!value) {
          return "Name is required.";
        }
        break;
      case "email":
        if (!value) {
          return "Email is required.";
        } else if (!strongEmailRegex.test(value)) {
          return "Please enter a valid email address.";
        }
        break;
      case "password":
        if (!value) {
          return "Password is required.";
        } else if (!passwordRegex.test(value)) {
          return "Password must be 8+ characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 digit.";
        }
        break;
      case "confirmPassword":
        if (!value) {
          return "Confirming password is required.";
        } else if (value !== formData.password) {
          return "Passwords do not match.";
        }
        break;
      case "termsAgreed":
        if (!value) {
          return "You must agree to the terms and policy.";
        }
        break;
      default:
        return "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    // Validate the changed field
    const error = validateField(name, fieldValue);

    // Update formData and errors states
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case "auth/email-already-in-use":
        return "This email is already in use.";
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/operation-not-allowed":
        return "Operation not allowed. Please contact support.";
      case "auth/weak-password":
        return "Password is too weak.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsRegisterLoading(true);
    try {
      const newUser = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password,
      );
      await updateProfile(newUser.user, {
        displayName: formData.name,
      });
      // Redirect to dashboard or another page
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firebase: getErrorMessage(error.code),
      }));
    } finally {
      setIsRegisterLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setIsGoogleSignInLoading(true);
    try {
      await signInWithPopup(auth, provider);
      // Redirect to dashboard or another page
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firebase: getErrorMessage(error.code),
      }));
    } finally {
      setIsGoogleSignInLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <img src={logo} alt="FillyForm" className="logo" />
      <form onSubmit={handleRegister}>
        <div className="form-header">
          <h1>Sign up</h1>
          <p>
            Already have an account?{" "}
            <Link className="link" to={`/login`}>
              Sign in here
            </Link>
          </p>
        </div>

        <button
          type="button"
          onClick={handleGoogleSignIn}
          className={`sm-button ${
            isGoogleSignInLoading ? "loading disabled" : ""
          }`}
          disabled={isGoogleSignInLoading}
        >
          <img src={googleLogo} alt="Sign up with Google" /> Sign up with Google
        </button>
        <div className="auth-spacer">or</div>
        <fieldset>
          <div className="form-field">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
              className={errors.name ? "has-error" : ""}
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
        </fieldset>
        <fieldset>
          <div className="form-field">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address"
              className={errors.email ? "has-error" : ""}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
        </fieldset>
        <fieldset>
          <div className="form-field">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder={showPassword ? "Password" : "********"}
              className={errors.password ? "has-error" : ""}
              new-password="true"
              autoComplete="true"
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="icon show-password"
            >
              {showPassword ? "visibility" : "visibility_off"}
            </span>
            {errors.password && <div className="error">{errors.password}</div>}
          </div>
        </fieldset>
        <fieldset>
          <div className="form-field">
            <label>Confirm password</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              onPaste={(e) => e.preventDefault()}
              placeholder={
                showConfirmPassword ? "Confirm Password" : "********"
              }
              className={errors.confirmPassword ? "has-error" : ""}
              new-password="true"
              autoComplete="true"
            />
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="icon show-password"
            >
              {showConfirmPassword ? "visibility" : "visibility_off"}
            </span>
            {errors.confirmPassword && (
              <div className="error">{errors.confirmPassword}</div>
            )}
          </div>
        </fieldset>
        <fieldset>
          <div className="form-field-checkbox">
            <label className={errors.termsAgreed ? "has-error" : ""}>
              <input
                type="checkbox"
                name="termsAgreed"
                checked={formData.termsAgreed}
                onChange={handleInputChange}
                className="visually-hidden custom-checkbox"
              />
              <span className="checkbox-indicator"></span>I agree to the{" "}
              <a
                href="https://filly-form.com/terms"
                target="_blank"
                className="link"
                rel="noreferrer"
              >
                Terms & Policy
              </a>
            </label>
            {errors.termsAgreed && (
              <div className="error">{errors.termsAgreed}</div>
            )}
          </div>
        </fieldset>
        <button
          type="submit"
          className={`primary-button ${
            isRegisterLoading ? "loading disabled" : ""
          }`}
          disabled={isRegisterLoading}
        >
          Sign up
        </button>
        {errors.firebase && <div className="error">{errors.firebase}</div>}
      </form>
      <p className="copyrights">
        © {new Date().getFullYear()} Answers Automate
      </p>
    </div>
  );
};

export default Register;
