import React from "react";
import DashboardTabs from "../../../components/general/DashboardTabs";
import { Route, Routes } from "react-router-dom";
// import Settings from "./Settings";
import Fields from "./Fields";
import FieldForm from "./FieldForm";
import { useDashboard } from "../../../context/DashboardContext";

const WidgetSettings = () => {
  const { dashboardData } = useDashboard();
  const planLimit = dashboardData?.activePlan?.limitations?.fields;
  const fields = dashboardData?.company?.fields;
  const isLimitReached = planLimit !== undefined && fields.length >= planLimit;

  let tabs = [];
  if (isLimitReached) {
    tabs = [
      {
        title: "All",
        url: "/dashboard/fields/all",
      },
    ];
  } else {
    tabs = [
      // { title: "Settings", url: "/dashboard/widget/settings" },
      {
        title: "All",
        url: "/dashboard/fields/all",
      },
      {
        title: "Add new",
        url: "/dashboard/fields/add",
      },
    ];
  }

  return (
    <>
      <DashboardTabs links={tabs} />
      <Routes>
        {/* <Route path="settings" element={<Settings/>} /> */}
        <Route path="all" element={<Fields />} />
        <Route path="add" element={<FieldForm />} />
        <Route path="add/preview" element={<FieldForm />} />
        <Route path="edit/:fieldId" element={<FieldForm />} />
        <Route path="edit/:fieldId/preview" element={<FieldForm />} />
      </Routes>
    </>
  );
};

export default WidgetSettings;
