import React, { useState, useEffect } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { useAuth } from "../../../context/AuthContext";
import { useNotification } from "../../../context/NotificationContext";
import {
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signOut,
} from "firebase/auth";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { auth } from "../../../firebase";

const UserDetails = () => {
  const { dashboardData } = useDashboard();
  const { currentUser } = useAuth();
  const { addNotification } = useNotification();

  const [formData, setFormData] = useState({
    displayName: dashboardData?.user?.displayName || "",
    email: currentUser.email || "",
    password: "",
    currentPassword: "",
    invalidateSessionsEmail: false,
    invalidateSessionsPassword: false,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    displayName: false,
    email: false,
    password: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      displayName: dashboardData?.user?.displayName || "",
      email: currentUser.email || "",
      password: "",
      currentPassword: "",
      invalidateSessionsEmail: false,
      invalidateSessionsPassword: false,
    }));
  }, [dashboardData.user, currentUser.email]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = (type) => {
    let isValid = true;
    const newErrors = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (type === "email" && !formData.email.match(emailRegex)) {
      newErrors["email"] = "Invalid email format.";
      isValid = false;
    }

    if (type === "password" && !formData.password.match(passwordRegex)) {
      newErrors["password"] =
        "Password must be 8+ characters, including 1 uppercase, 1 lowercase, 1 special character, and 1 digit.";
      isValid = false;
    }

    if (
      (type === "email" || type === "password") &&
      !formData.currentPassword
    ) {
      newErrors["currentPassword"] = "Current password is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    if (!validateForm(type)) return;

    setIsLoading((prev) => ({ ...prev, [type]: true }));

    try {
      if (type === "displayName") {
        const userDocRef = doc(
          db,
          "users",
          dashboardData.user?.id || "undefinedUserId",
        );
        await updateDoc(userDocRef, { displayName: formData.displayName });
        addNotification({
          icon: "check_circle",
          title: "Success",
          message: "Display Name updated successfully.",
          type: "success",
        });
      } else if (type === "email" || type === "password") {
        const credential = EmailAuthProvider.credential(
          currentUser.email,
          formData.currentPassword,
        );
        await reauthenticateWithCredential(currentUser, credential);

        if (type === "email") {
          await updateEmail(currentUser, formData.email);
          addNotification({
            icon: "check_circle",
            title: "Success",
            message: "Email updated successfully.",
            type: "success",
          });
        }

        if (type === "password") {
          await updatePassword(currentUser, formData.password);
          addNotification({
            icon: "check_circle",
            title: "Success",
            message: "Password updated successfully.",
            type: "success",
          });
        }

        // Optionally invalidate all sessions if checked
        if (
          (type === "email" && formData.invalidateSessionsEmail) ||
          (type === "password" && formData.invalidateSessionsPassword)
        ) {
          await signOut(auth);
        }
      }
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
      addNotification({
        icon: "error",
        title: "Error",
        message: `Failed to update ${type}. ${error.message}`,
        type: "error",
      });
      setErrors((prev) => ({ ...prev, [type]: error.message }));
    } finally {
      setIsLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  return (
    <>
      <div className="profile-layout">
        <div className="description">
          <div className="title-section">
            <h2>Your details</h2>
            <p>A Snapshot of Your Essential Business Information</p>
          </div>
        </div>
        <div className="actions">
          <form onSubmit={(e) => handleSubmit(e, "displayName")}>
            <fieldset>
              <div className="form-field">
                <label>Display Name</label>
                <input
                  type="text"
                  name="displayName"
                  value={formData.displayName || ""}
                  onChange={handleChange}
                  maxLength={100}
                  placeholder="Your display name"
                  className={errors.displayName ? "has-error" : ""}
                />
                {errors.displayName && (
                  <div className="error">{errors.displayName}</div>
                )}
              </div>
            </fieldset>
            <button
              type="submit"
              className={`primary-button ${isLoading.displayName ? "loading" : ""}`}
              disabled={isLoading.displayName}
            >
              Update Display
            </button>
          </form>
        </div>
      </div>

      {currentUser.emailVerified &&
        currentUser.providerData[0]?.providerId !== "google.com" && (
          <>
            {/* <div className="profile-layout">
              <div className="description">
                <div className="title-section">
                  <h2>Your email</h2>
                  <p>You can change your email</p>
                </div>
              </div>
              <div className="actions">
                <form onSubmit={(e) => handleSubmit(e, "email")}>
                  <fieldset>
                    <div className="form-field">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email || ""}
                        onChange={handleChange}
                        maxLength={100}
                        placeholder="Email"
                        className={errors.email ? "has-error" : ""}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                    </fieldset>
                    <fieldset>
                    <div className="form-field">
                      <label>Current Password</label>
                      <input
                        type="password"
                        name="currentPassword"
                        value={formData.currentPassword || ""}
                        onChange={handleChange}
                        maxLength={100}
                        placeholder="Current Password"
                        className={errors.currentPassword ? "has-error" : ""}
                      />
                      {errors.currentPassword && (
                        <div className="error">{errors.currentPassword}</div>
                      )}
                    </div>
                  </fieldset>
                  <fieldset>
                  <div className="form-field-checkbox">
                    <label className="">
                      <input
                        type="checkbox"
                        name="invalidateSessionsEmail"
                        className="visually-hidden custom-checkbox"
                        checked={formData.invalidateSessionsEmail}
                        onChange={handleChange}
                      />
                      <span className="checkbox-indicator"></span>
                      Invalidate all sessions on email update
                    </label>
                  </div>
                </fieldset>
                  <button
                    type="submit"
                    className={`primary-button ${
                      isLoading.email ? "loading" : ""
                    }`}
                    disabled={isLoading.email}
                  >
                    Update Email
                  </button>
                </form>
              </div>
            </div> */}

            <div className="profile-layout">
              <div className="description">
                <div className="title-section">
                  <h2>Your password</h2>
                  <p>You can update your password</p>
                </div>
              </div>
              <div className="actions">
                <form onSubmit={(e) => handleSubmit(e, "password")}>
                  <fieldset>
                    <div className="form-field">
                      <label>New Password</label>
                      <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={formData.password || ""}
                        onChange={handleChange}
                        maxLength={100}
                        placeholder="New Password"
                        className={errors.password ? "has-error" : ""}
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className="icon show-password"
                      >
                        {showPassword ? "visibility" : "visibility_off"}
                      </span>
                      {errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className="form-field">
                      <label>Current Password</label>
                      <input
                        type={showPasswordConfirmation ? "text" : "password"}
                        name="currentPassword"
                        value={formData.currentPassword || ""}
                        onChange={handleChange}
                        maxLength={100}
                        placeholder="Current Password"
                        className={errors.currentPassword ? "has-error" : ""}
                      />
                      <span
                        onClick={() =>
                          setShowPasswordConfirmation(!showPasswordConfirmation)
                        }
                        className="icon show-password"
                      >
                        {showPasswordConfirmation
                          ? "visibility"
                          : "visibility_off"}
                      </span>
                      {errors.currentPassword && (
                        <div className="error">{errors.currentPassword}</div>
                      )}
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className="form-field-checkbox">
                      <label className="">
                        <input
                          type="checkbox"
                          name="invalidateSessionsPassword"
                          className="visually-hidden custom-checkbox"
                          checked={formData.invalidateSessionsPassword}
                          onChange={handleChange}
                        />
                        <span className="checkbox-indicator"></span>
                        Invalidate all sessions on password update
                      </label>
                    </div>
                  </fieldset>
                  <button
                    type="submit"
                    className={`primary-button ${
                      isLoading.password ? "loading" : ""
                    }`}
                    disabled={isLoading.password}
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default UserDetails;
