import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { useDashboard } from "../../../context/DashboardContext";
import InsightsData from "./InsightsData";
import GeneratedContent from "./GeneratedContent";
import DashboardTabs from "../../../components/general/DashboardTabs";

const Insights = () => {
  const { dashboardData } = useDashboard();
  const navigate = useNavigate();
  const location = useLocation();

  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  console.log(dashboardData);
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [chartType, setChartType] = useState("Bar");
  const [dateRange, setDateRange] = useState("Last 7 days");
  const [granularity, setGranularity] = useState("day");
  const [startDate, setStartDate] = useState(
    sevenDaysAgo.toISOString().split("T")[0],
  );
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);

  const publishedFields =
    dashboardData?.company?.fields?.filter(
      (field) => field.published === "true",
    ) || [];
  const hasPublishedFields = publishedFields.length > 0;

  // Tabs
  const tabs = hasPublishedFields
    ? [
        { title: "Data", url: `/dashboard/insights/data/${selectedFieldId}` },
        {
          title: "Generated content",
          url: `/dashboard/insights/generated/${selectedFieldId}/1`,
        },
      ]
    : [
        { title: "Data", url: "#" },
        { title: "Generated content", url: "#" },
      ];

  useEffect(() => {
    if (hasPublishedFields) {
      const pathParts = location.pathname.split("/");
      const isDataRoute = location.pathname.includes("/data/");
      const isGeneratedRoute = location.pathname.includes("/generated/");

      if (!isDataRoute && !isGeneratedRoute) {
        // Default to data route for the first published field
        const defaultFieldId = publishedFields[0].id;
        setSelectedFieldId(defaultFieldId);
        navigate(`/dashboard/insights/data/${defaultFieldId}`, {
          replace: true,
        });
      } else {
        // Extract fieldId from URL
        let fieldIdIndex;
        if (isDataRoute) fieldIdIndex = pathParts.indexOf("data") + 1;
        if (isGeneratedRoute) fieldIdIndex = pathParts.indexOf("generated") + 1;

        const urlFieldId = pathParts[fieldIdIndex];
        const isValidFieldId = publishedFields.some(
          (field) => field.id === urlFieldId,
        );
        const finalFieldId = isValidFieldId
          ? urlFieldId
          : publishedFields[0].id;

        if (!isValidFieldId) {
          // Redirect to a valid fieldId
          if (isDataRoute) {
            navigate(`/dashboard/insights/data/${finalFieldId}`, {
              replace: true,
            });
          } else {
            navigate(`/dashboard/insights/generated/${finalFieldId}/1`, {
              replace: true,
            });
          }
        }
        setSelectedFieldId(finalFieldId);
      }
    } else {
      // No published fields
      setSelectedFieldId("");
      setLoading(false);
    }
  }, [publishedFields, location.pathname, navigate, hasPublishedFields]);

  const handleFieldChange = (e) => {
    const newFieldId = e.target.value;
    if (newFieldId !== selectedFieldId) {
      setSelectedFieldId(newFieldId);
      setCurrentPage(1);

      if (location.pathname.includes("generated")) {
        navigate(`/dashboard/insights/generated/${newFieldId}/1`);
      } else {
        navigate(`/dashboard/insights/data/${newFieldId}`);
      }
    }
  };

  const handleTabChange = (tabUrl) => {
    if (!hasPublishedFields || tabUrl === "#") return;
    navigate(tabUrl);
  };

  return (
    <>
      {!hasPublishedFields ? (
        <div className="no-data-wrapper">
          <span className="icon">monitoring</span>
          <h2>No data to display</h2>
          <p>
            You will start seeing data once you published at least one field.
          </p>
          <button
            className="primary-button"
            onClick={() => navigate("/dashboard/fields/add")}
          >
            Add a field
          </button>
        </div>
      ) : (
        <>
          <DashboardTabs
            links={tabs}
            onTabChange={handleTabChange}
            currentPath={location.pathname}
          />
          <Routes>
            <Route
              path="data/:fieldId"
              element={
                <InsightsData
                  dashboardData={dashboardData}
                  selectedFieldId={selectedFieldId}
                  setSelectedFieldId={setSelectedFieldId}
                  chartType={chartType}
                  setChartType={setChartType}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  granularity={granularity}
                  setGranularity={setGranularity}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  handleFieldChange={handleFieldChange}
                />
              }
            />
            <Route
              path="generated/:fieldId/:page"
              element={
                <GeneratedContent
                  dashboardData={dashboardData}
                  selectedFieldId={selectedFieldId}
                  setSelectedFieldId={setSelectedFieldId}
                  generatedData={generatedData}
                  setGeneratedData={setGeneratedData}
                  entriesPerPage={entriesPerPage}
                  setEntriesPerPage={setEntriesPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  publishedFields={publishedFields}
                  handleFieldChange={handleFieldChange}
                  loading={loading}
                />
              }
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default Insights;
